import axios from "axios";

const getHeaders = () => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json"
  };

  const token = localStorage.getItem("token");
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

const buildQueryParams = queryParams =>
  queryParams
    ? new URLSearchParams(
        Object.keys(queryParams).reduce(
          (acc, value) =>
            queryParams[value]
              ? {
                  ...acc,
                  [value]: queryParams[value]
                }
              : acc,
          {}
        )
      )
    : "";

export const getUserProfile = () =>
  axios.get(`${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/user/profile`, {
    headers: getHeaders()
  });

export const generateTwofa = () =>
  axios({
    url: `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/two-fa/generate`,
    method: "POST",
    headers: getHeaders()
  });

export const GenerateWalletAddress = (orgId, network, type) =>
  axios({
    url: `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/retail/generate/${network}/address/${type}`,
    method: "POST",
    headers: getHeaders()
  });

export const disableTwofa = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/two-fa/disable`,
    data,
    { headers: getHeaders() }
  );

export const enableTwofa = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/two-fa/enable`,
    data,
    { headers: getHeaders() }
  );

export const apiGetTwoFaStatus = () =>
  axios.get(`${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/two-fa/status`, {
    headers: getHeaders()
  });

export const logout = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/auth/logout`,
    data,
    { headers: getHeaders() }
  );

export const apiLoginWithPassword = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/auth/login`,
    data,
    { headers: getHeaders() }
  );

export const apiResendOtp = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/email/resend/login/email-code`,
    data,
    { headers: getHeaders() }
  );

export const apiForgetPassword = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/password/forgot`,
    data,
    { headers: getHeaders() }
  );

export const apiResetPassword = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/password/reset`,
    data,
    { headers: getHeaders() }
  );

export const apiChangePassword = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/password/change`,
    data,
    { headers: getHeaders() }
  );

export const verifyEmail = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/email/verification/approve`,
    data,
    { headers: getHeaders() }
  );

export const resendEmailVerification = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/email/verification/request`,
    data,
    { headers: getHeaders() }
  );

export const apiLoginOtp = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/email/verify/login/email-code`,
    data,
    { headers: getHeaders() }
  );

export const apiTwoFa = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/auth/login/two-fa`,
    data,
    { headers: getHeaders() }
  );

export const apiGetWithdrawCurrencies = orgId =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/withdrawal/currencies/`,
    { headers: getHeaders() }
  );

export const apiGetCurrencies = apiKey =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/sell/currencies/${apiKey}`,
    { headers: getHeaders() }
  );

export const getBlockchains = () =>
  axios.get(`${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/blockchains/`, {
    headers: getHeaders()
  });

export const createInvoice = async (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/user-invoice`,
    data,
    { headers: getHeaders() }
  );

export const createOrderInvoice = async (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/order/invoice`,
    data,
    { headers: getHeaders() }
  );

export const getInvoices = async (orgId, page, perPage, queryParams) =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v2/org/${orgId}/user-invoices/${page}/${perPage}?${buildQueryParams(
      queryParams
    )}`,
    { headers: getHeaders() }
  );

export const getInvoicesConfigs = async () =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/user-invoices-configs`,
    { headers: getHeaders() }
  );

export const apiGetAccounts = orgId =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/accounts/1/10`,
    { headers: getHeaders() }
  );

export const apiGetAccountById = (orgId, accountId) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/account/${accountId}`,
    { headers: getHeaders() }
  );

export const apiGetWalletAddress = (orgId, type) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/retail/address/${type}`,
    { headers: getHeaders() }
  );

export const apiGetTransactions = (
  orgId,
  accountId,
  page,
  perPage,
  queryParams
) =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v2/org/${orgId}/accounts/transactions/${page}/${perPage}?${buildQueryParams(
      queryParams
    )}`,
    { headers: getHeaders() }
  );

export const apiGetOrderInvoices = (orgId, page, perPage, queryParams) =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v3/org/${orgId}/order/invoices/${page}/${perPage}?${buildQueryParams(
      queryParams
    )}`,
    { headers: getHeaders() }
  );

export const apiGetContacts = (orgId, page, perPage, queryParams) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/contacts${
      page && perPage ? `/${page}/${perPage}` : ""
    }?${buildQueryParams(queryParams)}`,
    { headers: getHeaders() }
  );

export const apiGetUserApiKeys = (orgId, page, perPage) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/api/keys${
      page && perPage ? `/${page}/${perPage}` : ""
    }`,
    { headers: getHeaders() }
  );

export const apiGetCountries = (query, page, perPage) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/countries?${
      query ? `search=${query}` : ""
    }${page && perPage ? `&page_number=${page}&per_page=${perPage}` : ""}`,
    { headers: getHeaders() }
  );

export const apiCreateContact = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/contacts/add`,
    data,
    { headers: getHeaders() }
  );

export const apiUpdateContact = (orgId, id, data) =>
  axios.patch(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/contacts/${id}/update`,
    data,
    { headers: getHeaders() }
  );

export const apiDeleteContact = (orgId, id) =>
  axios.delete(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/contacts/${id}/remove`,
    { headers: getHeaders() }
  );

export const payContactRequest = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/account/wallet-withdrawal/request-code`,
    data,
    { headers: getHeaders() }
  );

export const payContactManuallyRequest = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/account/manual-withdrawal-request/request-code`,
    data,
    { headers: getHeaders() }
  );

export const payContact = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/account/wallet-withdrawal/request`,
    data,
    { headers: getHeaders() }
  );

export const payContactManually = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/account/manual-withdrawal-request`,
    data,
    { headers: getHeaders() }
  );

export const deleteContactAddress = (
  orgId,
  contactId,
  contactAddressId,
  deleteOnlyAddress
) =>
  axios.delete(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v1/org/${orgId}/contacts/${contactId}/remove?${new URLSearchParams({
      contactAddressId,
      deleteOnlyAddress
    })}`,
    { headers: getHeaders() }
  );

export const recurrentJobRequest = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/recurring/job/request`,
    data,
    { headers: getHeaders() }
  );

export const recurrentJob = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/recurring/job/new`,
    data,
    { headers: getHeaders() }
  );

export const getRecurrentJobs = (orgId, page, perPage, queryParams) =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v2/org/${orgId}/recurring/jobs/list/${page}/${perPage}?${buildQueryParams(
      queryParams
    )}`,
    { headers: getHeaders() }
  );

export const recurrentJobAction = (orgId, rcOpId, options) =>
  axios.post(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v1/org/${orgId}/recurring/job/${rcOpId}/action?terminate=${!!options.terminate}&on_hold=${!!options.onHold}&active=${!!options.active}&updateTx=${!!options.updateTx}`,
    {},
    { headers: getHeaders() }
  );

export const apiGetRetailTransactions = (orgId, page = 1, perPage = 10, type) =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v1/org/${orgId}/retail/transactions/${page}/${perPage}${
      type ? `?type=${type}` : ""
    }`,
    { headers: getHeaders() }
  );

export const getOrderStats = (orgId, fromDate, toDate) =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v1/org/${orgId}/stats/orders?${
      fromDate && toDate ? `fromDate=${fromDate}&toDate=${toDate}` : ""
    }`,
    { headers: getHeaders() }
  );

export const getPaymentsStats = (orgId, fromDate, toDate) =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v1/org/${orgId}/stats/settlement?${
      fromDate && toDate ? `fromDate=${fromDate}&toDate=${toDate}` : ""
    }`,
    { headers: getHeaders() }
  );

export const apiGetOrders = (orgId, pageNumber, perPage, queryParams) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/orders/${
      pageNumber && perPage ? `${pageNumber}/${perPage}` : ""
    }?${buildQueryParams(queryParams)}`,
    { headers: getHeaders() }
  );

export const requestRefundCode = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/refund/request-code`,
    data,
    { headers: getHeaders() }
  );

export const requestRefund = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/refund/request`,
    data,
    { headers: getHeaders() }
  );

export const apiGetBanks = orgId =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/fiat-settlement/yellow-card/banks`,
    { headers: getHeaders() }
  );

export const apiGetBanksAccounts = (orgId, pageNumber, perPage, queryParams) =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v1/org/${orgId}/fiat-settlement/bank-accounts/${
      pageNumber && perPage ? `${pageNumber}/${perPage}` : ""
    }?${buildQueryParams(queryParams)}`,
    { headers: getHeaders() }
  );

export const apiBankVerify = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/fiat-settlement/verify/bank`,
    data,
    { headers: getHeaders() }
  );

export const apiSettlementRate = orgId =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/fiat-settlement/rate`,
    { headers: getHeaders() }
  );

export const apiSettlementEstimate = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/fiat-settlement/estimate`,
    data,
    { headers: getHeaders() }
  );

export const apiSettlementRequestCode = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/fiat-settlement/request-code`,
    data,
    { headers: getHeaders() }
  );

export const apiSettlementRequest = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/fiat-settlement/request`,
    data,
    { headers: getHeaders() }
  );

export const apiFiatSettlementOptions = (
  orgId,
  pageNumber,
  perPage,
  queryParams
) =>
  axios.get(
    `${
      process.env.NEXT_PUBLIC_REST_API_HOST
    }/api/v1/org/${orgId}/fiat-settlement/payment-options/${
      pageNumber && perPage ? `${pageNumber}/${perPage}` : ""
    }?${buildQueryParams(queryParams)}`,
    { headers: getHeaders() }
  );

export const apiFormDeposit = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/form/deposit`,
    data,
    { headers: getHeaders() }
  );
export const apiFormSettlement = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/form/settlement`,
    data,
    { headers: getHeaders() }
  );

export const apiContactRequestForm = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/form/contact`,
    data,
    { headers: getHeaders() }
  );

export const apiManualFiatSettlementEstimate = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/manual-fiat-settlement/estimate`,
    data,
    { headers: getHeaders() }
  );

export const apiSendSMSCodeManuelFiatSettlement = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/mpesa-fiat-settlement/request-code`,
    data,
    { headers: getHeaders() }
  );

export const apiSendSMSCodeNGNSettlement = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/request-code`,
    data,
    { headers: getHeaders() }
  );

export const apiManualFiatSettlementRequestMpesa = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/mpesa-fiat-settlement/request`,
    data,
    { headers: getHeaders() }
  );
export const apiManualFiatSettlementRequestNGN = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/request`,
    data,
    { headers: getHeaders() }
  );
export const apiBecomeAnAgentForm = data =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/form/agent`,
    data,
    { headers: getHeaders() }
  );

export const apiDepositMpesa = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/mpesa-fiat-settlement/deposit/request`,
    data,
    { headers: getHeaders() }
  );

export const apiNewInvoiceForm = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/order/invoice`,
    data,
    { headers: getHeaders() }
  );

export const apiManualFiatPHPSettlementOptions = (
  orgId,
  pageNumber = 1,
  perPage = 10,
  queryParams
) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/payment-options/${pageNumber}/${perPage}`,
    { headers: getHeaders() }
  );

export const apiManualFiatNGNSettlementOptions = (
  orgId,
  pageNumber = 1,
  perPage = 10,
  queryParams
) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/payment-options/${pageNumber}/${perPage}`,
    { headers: getHeaders() }
  );

export const apiManualPHPBankAccounts = (orgId, pageNumber = 1, perPage = 10) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/bank-accounts/${pageNumber}/${perPage}`,
    { headers: getHeaders() }
  );

export const apiGetCountryList = () =>
  axios.get(`${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/countries`, {
    headers: getHeaders()
  });

export const apiPHPDeposit = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/php-fiat-settlement/deposit/request`,
    data,
    { headers: getHeaders() }
  );

export const apiPHPSettlement = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/request`,
    data,
    { headers: getHeaders() }
  );

export const apiPHPWithdrawalEstimate = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/estimate`,
    data,
    { headers: getHeaders() }
  );

export const apiPHPWithdrawalRequestCode = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/php-fiat-settlement/request-code`,
    data,
    { headers: getHeaders() }
  );

export const apiSendSMSCodePHP = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/request-code`,
    data,
    { headers: getHeaders() }
  );

export const apiManualFiatSettlementMpesaRequesCode = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/request-code`,
    data,
    { headers: getHeaders() }
  );

export const apiNGNPaymentOptions = (
  orgId,
  pageNumber = 1,
  perPage = 10,
  queryParams
) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/payment-options/${pageNumber}/${perPage}`,
    { headers: getHeaders() }
  );

export const apiFiatOptions = (
  orgId,
  pageNumber = 1,
  perPage = 10,
  queryParams
) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/payment-options/${pageNumber}/${perPage}`,
    { headers: getHeaders() }
  );

export const apiNGNDeposit = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/deposit/request`,
    data,
    { headers: getHeaders() }
  );

export const apiNGNCurrencyPrice = data =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/currency/price/usdc/ngn`,
    data,
    { headers: getHeaders() }
  );

export const apiKESCurrencyPrice = data =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/currency/price/usdc/kes`,
    data,
    { headers: getHeaders() }
  );

export const apiKesBankSettlementRequest = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/request`,
    data,
    { headers: getHeaders() }
  );
export const apiManualFiatSettlementBankDeposit = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/deposit/request`,
    data,
    { headers: getHeaders() }
  );

export const apiManualMpesaBankAcctOptions = (
  orgId,
  pageNumber = 1,
  perPage = 10,
  queryParams
) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/bank-accounts/${pageNumber}/${perPage}`,
    { headers: getHeaders() }
  );

export const apiManualFiatSettlementOptions = (
  orgId,
  pageNumber = 1,
  perPage = 10,
  queryParams
) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/manual-fiat-settlement/payment-options/${pageNumber}/${perPage}`,
    { headers: getHeaders() }
  );

export const apiGetSupportedFiatCurrencies = orgId =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/org/${orgId}/currency/supported-fiat-currencies`,
    { headers: getHeaders() }
  );

export const apiFiatTransactionProofDoc = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/fiat-settlement/upload/transaction-proof/document`,
    data,
    { headers: getHeaders() }
  );
export const apiGetCurrencyRatesNGN = data =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/currency/price/usdc/ngn`,
    data,
    { headers: getHeaders() }
  );
export const apiGetCurrencyRatesKES = data =>
  axios.get(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v1/currency/price/usdc/kes`,
    data,
    { headers: getHeaders() }
  );

export const apiMpesaEstimate = (orgId, data) =>
  axios.post(
    `${process.env.NEXT_PUBLIC_REST_API_HOST}/api/v2/org/${orgId}/mpesa-fiat-settlement/estimate`,
    data,
    { headers: getHeaders() }
  );
